<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <basic-table
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="checkPermission(['SUPER_ADMIN'])"
          >新增</el-button
        >
        <el-button
          size="small"
          class="mt-r"
          @click="multipleFormDialog = true"
          v-if="checkPermission(['SUPER_ADMIN'])"
          >批量新增</el-button
        >
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date
            }}</span>

            <InlineForm
              v-model="scope.row.name"
              v-else-if="column.prop === 'name' && scope.row.isEdit"
              @confirm="onConfirmEdit(scope.row)"
              @cancel="onCancelEdit(scope.row)"
            />

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.canUpdate"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              修改
            </el-button>
            <el-button
              class="text-danger"
              v-if="scope.row.canDelete"
              @click.native.prevent="deleteRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="checkPermission(['SUPER_ADMIN'])"
          type="danger"
          size="small"
          @click="onMultipleDelete"
          :disabled="multipleSelected.length <= 0"
          :loading="deleteLoading"
          >批量删除 {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      :title="isEmprty(currentId) ? '新增类型' : '修改类型'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-dialog
      title="批量新增"
      :visible.sync="multipleFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetMerchantsTypesList,
  PutMerchantsTypeById,
  DeleteMerchantsTypeById,
  MultipleDeleteMerchantsTypes,
} from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import { getOrderType } from "@/utils/common";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import InlineForm from "@/components/InlineForm";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import { ReadLocalColumn } from "@/utils/table";
export default {
  components: {
    BasicTable,
    Form,
    InlineForm,
    MultipleForm,
    PageHeader
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {}
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "id",
            label: "ID",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "类型名称",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: "120",
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {};
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetMerchantsTypesList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              isEdit: false,
            };
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "createdAt";
      this.orderDirection = "descending";
      this.onResetFilter();
      this.onRefresh();
    },
    onResetFilter() {
      for (const key in this.tableFilters) {
        if (typeof this.tableFilters[key] === "object") {
          if (this.tableFilters[key] instanceof Array) {
            this.tableFilters[key] = [];
          } else {
            this.tableFilters[key] = null;
          }
        } else {
          this.tableFilters[key] = "";
        }
      }
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      row.isEdit = true;
    },
    onFormCancel() {
      this.currentId = "";
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    onFormSuccess() {
      this.onRefresh();
    },
    deleteRow(row) {
      this.$msgbox({
        title: "删除提示",
        message: `是否确定要删除【${row.name}】`,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            DeleteMerchantsTypeById(row.id)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            done();
          }
        },
      });
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onMultipleDelete() {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let ids = [];
      this.multipleSelected.forEach((item) => {
        ids.push(item.id);
      });
      let nameString = "";
      nameString = this.multipleSelected.map((item) => item.name).toString();
      const h = this.$createElement;
      let message = h(
        "p",
        { style: "word-break:break-all" },
        `是否确定要删除【${nameString}】？`
      );
      this.$msgbox({
        title: "删除提示",
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.deleteLoading = true;
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            MultipleDeleteMerchantsTypes(ids)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                this.deleteLoading = false;
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                this.deleteLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            done();
          }
        },
      });
    },
    onConfirmEdit(row) {
      if(isEmprty(row.name)){
        this.$message.warning("名称不能为空");
        return false
      }
      PutMerchantsTypeById(row.id, { name: row.name })
        .then(() => {
          this.$message.success("修改成功");
          row.isEdit = false;
        })
        .catch((err) => {
          this.onRefresh();
          ShowApiError("修改失败", err);
        });
    },
    onCancelEdit() {
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>